import axios from "axios";
import { Toast } from "@douyinfe/semi-ui";

 axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.baseURL = "/api";

// 设置全局的 axios 响应拦截器
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            // 服务器返回了错误响应
            console.error(`服务器错误: ${error.response.status}`, error.response.data);
            // 根据状态码显示不同的错误提示
            if (error.response.status === 404) {
                showErrorMessage("资源未找到");
            } else if (error.response.status === 500) {
                showErrorMessage("服务器内部错误，请稍后再试");
            } else if (error.response.status === 401) {
                window.location.href = '/admin/login';
                //redirect('/admin/login');
            }
        } else if (error.request) {
            // 请求已发出，但未收到响应
            console.error("网络错误，请检查您的网络连接");
            showErrorMessage("网络连接异常，请稍后再试");
        } else {
            // 其他错误设置
            console.error("请求设置错误", error.message);
            showErrorMessage("请求出现异常，请稍后再试");
        }
        return Promise.reject(error);
    }
);

function showErrorMessage(msg) {
    Toast.error(msg);
}

const Api = {
    liList: function liList(data) {
        return axios.get("/v5/sku/diamond-page", data)
    },
    placeOrder: function placeOrder(data) {
        return axios.post("/v5/pay/pre-pay", data)
    },
    get2: function get(path, callback) {
        const headers = {"Content-Type": "application/x-www-form-urlencoded", "Accept": "application/json"};
        axios
            .get(path, { headers: headers })
            //.get(path)
            .then((res) => {
                callback(res);
            })
            .catch((e) => {
                console.log(e);
            });
    },
    addParamsToPath: function addParamsToPath(urlPath, params) {
        let queryStartIndex = urlPath.indexOf("?");
        let basePath = queryStartIndex === -1 ? urlPath : urlPath.slice(0, queryStartIndex);
        let queryString = queryStartIndex === -1 ? "" : urlPath.slice(queryStartIndex + 1);

        let existingParams = new URLSearchParams(queryString);

        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                existingParams.set(key, params[key]);
            }
        }

        return `${basePath}?${existingParams.toString()}`;
    },

    getNumber: function getNumber(data) {
        return axios.get(`/v5/user/mini?number=${data}`)
    },

    payOrderCode: function payOrderCode(data) {
        return axios.get(`/v5/pay/order/query?code=${data}`)
    },

    

};
export default Api;
