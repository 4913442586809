import React, { useState } from 'react'
import '../css/home.css'
import img1 from "../images/1.png";
import img2 from "../images/2.png";
import img3 from "../images/3.png";
import img4 from "../images/4.png";

import iphoneImg from "../images/苹果.png";
import AndroidImg from "../images/安卓.png";

function homePage() {
     const iphoneClick = () => {
          alert('正在维护，敬请期待')
     };

     const AndroidClick = (e) => {
          e.preventDefault()
          alert('正在维护，敬请期待')
         
          //window.location.href = 'https://www.51dazi.com/download/android/daziapp.apk';
     }

     return (
          <div>
               <div className='content'>
                    <div className='content_one'>
                         <img src={img1} alt="" style={{ width: '100%', }}/>
                         <div className='content_one_btn'>
                              <div className='content_one_btn_div' onClick={ iphoneClick } >
                                   <img src={iphoneImg} alt="" style={{ width: '15%', }}/>
                                   <p style={{ marginLeft:'10px'}}>苹果版下载</p>
                              </div>
                              <div className='content_one_btn_div' onClick={AndroidClick} >
                                   <img src={AndroidImg} alt="" style={{ width: '15%', }} />
                                   <p style={{ marginLeft: '10px' }}>安卓版下载</p>
                              </div>
                         </div>
                    </div>


                    <div className='content_one'>
                         <img src={img2} alt="" style={{ width:'100%'}}/>
                    </div>
                    <div className='content_one'>
                         <img src={img3} alt="" style={{ width: '100%' }} />
                    </div>
                    <div className='content_one'>
                         <img src={img4} alt="" style={{ width: '75%' }} />
                    </div>
               </div>
          </div>

     )
}

export default homePage;

