import React, { useState } from 'react'
import '../css/home.css'
import logo from "../images/logo.png";
import HomePage from '../components/homePage'
import Recharge from '../components/Recharge'

function Home() {
     const [activeTab, setActiveTab] = useState(0);
     const handleTabClick = (index) => {
          setActiveTab(index);
     };

     const imgClick = () => {
          alert('正在维护，敬请期待')
     };

     return (
          <div>
               <div className='header'>
                    <div style={{width:'70%',margin:'0 auto',display:'flex'}}>
                         <div className='header_left'>
                              <img src={logo} alt="" onClick={imgClick}/>
                         </div>

                         <div className="header_right">
                              <p onClick={() => handleTabClick(0)} className={activeTab === 0 ? "P_before" : "P_after"}>
                                   <span>首页</span>
                              </p>
                              <p onClick={() => handleTabClick(1)} className={activeTab === 1 ? "P_before" : "P_after" }>
                                   <span>充值</span>
                              </p>
                         </div>
                    </div>
               </div>

               <div className="content">
                    {activeTab === 0 && (
                         <HomePage></HomePage>
                    )}
                    
                    {activeTab === 1 && (
                         <Recharge></Recharge>
                    )}
               </div>


               <div className='footer'>
                    <div><p>天津搭子科技有限公司</p></div>
                    <div>
                         <span>
                              增值电信业务经营许可证: 津B2-20240922
                         </span>
                         <span style={{ padding: "0 5px" }}>·</span>
                         <span>
                              APP备案: 津ICP备2024017759号-2A
                         </span>
                         <span style={{ padding: "0 5px" }}>·</span>
                         <span>
                              文网文: 津网文（2024）3198-724号
                         </span>
                         <span style={{ padding: "0 5px" }}>·</span>
                         <span>
                              ICP备: <a href='https://beian.miit.gov.cn/'>津ICP备202417759号-1</a>
                         </span>
                    </div>
                    <div> <p>举报邮箱:daziapp@163.com</p></div>

                    {/* <p>天津搭子科技有限公司</p>
                    <p>备案号:京ICP备15050374号-1京网文[2023]1830-065号增值电信业务经营许可证:京ICP证160193号京公网安备11010702001839号</p>
                    <p>举报邮箱:dazlapp@163.com</p> */}
               </div>
          </div>
          
     )
}

export default Home;