import React, { useState, useEffect } from "react";
import '../css/recharge.css'
import { Input, Toast, Modal, Button } from "@douyinfe/semi-ui";

import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../utils/Api";
import zuanImg from "../images/钻石.png";
import wxImg from "../images/微信支付@2x.png";
import zfbImg from "../images/支付宝.png";

const Recharge = () => {
     const iptChange = (e)=>{
          console.log(e.target.value);
     }
     const List = [
          { amount: 180, price: 18 },
          { amount: 300, price: 30 },
          { amount: 980, price: 980 },
          { amount: 2980, price: 298 },
          { amount: 5180, price: 518 },
          { amount: 6480, price: 648 },
          { amount: 8980, price: 898 },
          { amount: 20000, price: 2000 },
          { amount: 50000, price: 5000 },
     ];

     const Lists = [
          { amount: '微信', price: 30, pic: wxImg },
          { amount: '支付宝', price: 18, pic: zfbImg },
     ];
     const [selectedDiv, setSelectedDiv] = useState(0);
     const [selectedDiv1, setSelectedDiv1] = useState(0);
     const handleClick = (index, item) => {
          setSelectedDiv(index);
          console.log(item);
     };
     const handleClick1 = (index, item) => {
          setSelectedDiv1(index);
          console.log(item.amount);
     };

     const btnClick = () => {
          alert('在维护中，敬请期待')
     };

     
     return (
          <div className='bg'>
               <div className='bg_content'>
                    <div className="bg_content_one" >
                         <p style={{ marginRight: '36px' }}>充值ID</p>
                         <input placeholder="请输入充值用户ID" className="ipt1" onChange={iptChange}/>
                    </div>
                    <div className="bg_content_one">
                         <p>历史记录</p>
                         <input placeholder="10211929" className="ipt2" />
                         <input placeholder="10211929" className="ipt2" />
                    </div>

                    <div className="bg_amount">
                         <p>充值金额</p>
                         <div style={{ width: '650px', display: "flex", flexWrap: "wrap", justifyContent: " flex-start" }}>
                              {List.map((item, index) => (
                                   <div
                                        className={selectedDiv === index ? "amount_other" : "amount_others"}
                                        onClick={() => handleClick(index, item)}
                                        key={index}
                                   >
                                        <div className="amount_other_top">
                                             <img src={zuanImg} alt="" style={{ width: "15px", height: "15px", margin: "11px 0 0 0" }} />
                                             <p>{item.amount}</p>
                                        </div>
                                        <p>￥{item.price}</p>
                                   </div>
                              ))}
                         </div>

                    </div>

                    <div className="bg_paymentMethod">
                         <p>支付方式</p>
                         {Lists.map((item, index) => (
                              <div
                                   className={selectedDiv1 === index ? "amount_other" : "amount_others"}
                                   onClick={() => handleClick1(index, item)}
                                   key={index}
                              >
                                   <img src={item.pic} alt="" style={{ width: "15px", height: "15px", }} />
                                   <p>{item.amount}</p>
                              </div>
                         ))}
                    </div>

                    <div className="bg_tips">
                         <p>*温馨提示:</p>
                         <p>1.钻石用于聊天室、消息中购买礼物</p>
                         <p>2.充值和活动的钻石不可提现</p>
                         <p>3.充值遇到问题，请联系 我的客服</p>
                    </div>


                    <div className="bg_btnfooter">
                         <button className="btn" onClick={btnClick}>立即充值</button>
                    </div>
               </div>
          </div>  
     );
};

export default Recharge;